const config = {
    size: {
        screen: {
            break_min: 0,
            break_xs: 320,
            break_sm: 540,
            break_md: 960,
            break_ls: 1100,
            break_xl: 1600,
        },
        el: {
            header: 120,
            headerFixed: 100
        }
    },
    google: {
        map_api_key: 'AIzaSyCZS6_aAmRgDyRxw-As1l_UAZCFIuY6CQo',
        map_id: null
    },
    headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    },
    fetch:{
        'base_url': null,
    },
    thanks:{
        'title': "Спасибо что обратились к нам!",
        'description': "Мы свяжемся с вами для уточнения деталей.",
    },
}

export default config
