<template>
    <component :is="type"
               :href="href"
               class="btn"
               @click="onClick($event)">
        <slot v-if="$slots.default || name">{{ name}}</slot>
        <slot v-if="$slots.icon" name="icon"></slot>
    </component>
</template>

<script>
    export default {
        props: {
            name: String,
            href: {
                type: String,
                display: null
            }
        },

        setup(props, {emit}) {
            let type = props.href ? 'a' : 'button';
            function onClick(e) {
                emit('onClick', e);
            }

            return {
                onClick,
                type
            }
        }
    }
</script>
