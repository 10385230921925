import {watch} from 'vue'

export default function useOverflow(props, emit) {
    function onKeyDown(e) {
        if (props.show && e.keyCode === 27) onClose();
    }

    function onClose() {
        if (props.show) {
            emit('onClose', false);
            emit('update:show', false);
        }
    }

    watch(
        () => props.show,
        (show) => {
            if (show) {
                document.body.style.overflow = "hidden"
                emit('onShow', true)
            } else {
                document.body.style.overflow = null
                emit('onClose', false)
            }
        }
    )

    return {
        onClose,
        onKeyDown
    }
}
