<template>
    <Teleport to="body">
        <transition name="opacity">
            <section class="modal" v-if="show"
                     :class="{modal_divide: divide}"
            >
                <div class="modal__wrapper">
                    <div class="modal__container" @click="closeOutSideHandler">
                        <v-close class="modal__close" @click="closeHandler" v-if="closeBtnOutSide"></v-close>
<!--                        <div class="modal__content" :style="style" v-click-outside="closeHandler">-->
                        <div class="modal__content" ref="content" :style="style">
                            <v-close class="modal__close" @click="closeHandler" v-if="!closeBtnOutSide"></v-close>
                            <header v-if="$slots.header" class="modal__header">
                                <slot name="header"></slot>
                            </header>
                            <main v-if="$slots.default" class="modal__body">
                                <slot name="default"></slot>
                            </main>
                            <footer v-if="$slots.footer" class="modal__footer">
                                <slot name="footer"></slot>
                            </footer>
                        </div>
                    </div>
                </div>
                <div class="modal__overlay" v-show="show"></div>
            </section>
        </transition>
    </Teleport>
</template>

<script>
    import {computed, onMounted, onUnmounted, ref} from "vue";

    import useOverflow from "@use/overflow";

    import Close from '@components/ui/Close'

    export default {
        name: "Modal",
        props: {
            divide: {
                type: Boolean,
                default: false
            },
            show: {
                type: Boolean,
                default: false
            },
            width: {
                type: [Number, String],
            },
            position: {
                type: String,
                default: 'center'
            },
            closeBtnOutSide: {
                type: Boolean,
                default: false
            },
            outSide: {
                type: Boolean,
                default: true
            },
        },
        components: {
            'v-close': Close,
        },
        setup(props, {emit}) {
            const {onClose: closeHandler, onKeyDown} = useOverflow(props, emit);
            let content = ref(null);

            onMounted(() => {document.addEventListener("keydown", onKeyDown)})
            onUnmounted(() => {document.removeEventListener("keydown", onKeyDown)})

            let style = computed(()=>{
                return props.width ? `width: ${parseInt(props.width)}px` : null
            })

            function closeOutSideHandler(e) {
                if (props.outSide && !!content.value) {
                    if (!content.value.contains(e.target)) {
                        closeHandler();
                    }
                }
            }

            return {
                style,
                content,
                closeHandler,
                closeOutSideHandler,
            }
        },
    }
</script>

<style></style>
