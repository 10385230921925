import {minLength, required} from "@utils/validators";
import useTemplate from "@use/native/template";
import usePhoneMask from "@use/native/phoneMask";

let phoneField = {
    name: 'phone',
    label: 'Телефон',
    value: '',
    type: 'tel',
    // clear: true,
    required: true,
    mask: usePhoneMask,
    validators: {
        required: {
            validator: required,
            message: useTemplate`${'label'} - Обязательное поле для заполнения`,
        },
        minLength: {
            validator: minLength(9),
            pattern: '',
            value: 9,
            message: useTemplate`${'label'} - Слишком короткое поле ${'length'} из ${'value'}`,
        },
    },
}

export default phoneField
