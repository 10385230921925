import {reactive, toRefs} from 'vue'
import getCookie from "@use/cookies";

export default function useFetch(url, options, csrf = true) {
    const state = reactive({
        error: null,
        fetching: true
    })

    let csrftoken = csrf ? getCookie('csrftoken') ?? null : null;

    const fetchData = async () => {
        // const url = new URL(url, BASE_URL);
        let init = {};
        if (csrf) {
            init = {
                headers: {'X-CSRFToken': csrftoken,},
                credentials: 'include'
            }
        }
        const request = new Request(url, init);

        // const request = new Request(url);

        try {
            const res = await fetch(request, options)
            return await res.json()
        } catch (errors) {
            state.error = errors
        } finally {
            state.fetching = false
        }
    }

    return {...toRefs(state), fetchData}
}
