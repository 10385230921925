<template>
    <div class="thanks">
        <div class="thanks__title" v-html="title"></div>
        <div class="thanks__description" v-html="description"></div>
        <div v-if="$slots.image || image" class="thanks__image">
            <slot name="image" v-html="image"></slot>
        </div>
    </div>
</template>

<script>
import {onMounted} from "vue";

export default {
    name: "Thanks",
    props: {
        title: {
            type: String,
            default: 'Спасибо что обратились к нам!'
        },
        description: {
            type: String,
            required: false
        },
        image: {
            type: String,
            required: false,
            default: null
        },
        show: {
            type: Boolean,
            default: false
        },
        timeout: {
            type: Number,
            default: 5000
        },
        disappear: {
            type: Boolean,
            default: true
        },
    }, setup(props, {emit}) {
        onMounted(() => {
            if (props.show && props.disappear) {
                setTimeout(() => {
                    if (props.show === true) emit('update:show', false);
                }, props.timeout);
            }
        });
    }
}
</script>

<style></style>
