<template>
    <form class="form" @submit="submitHandler">
        <div v-if="form.title || form.description" class="form__header">
            <div class="form__title" v-if="form.title">{{form.title}}</div>
            <div class="form__description" v-if="form.description">{{form.description}}</div>
        </div>

        <v-error v-if="form.errors.length" class="form__errors" :errors="form.errors"></v-error>

        <template v-if="form.groups.length">
            <div v-for="group in form.groups"
                 :key="group"
                 class="field-group">

                <div class="field-group__label">{{group.label}}</div>
                <div class="field-group__list">
                    <v-field v-for="key in group.keys"
                             :key="form.fields[key]"
                             :field="form.fields[key]"
                             :prefix="form.id"
                             :class="[form.fields[key].cls, {field_compact:form.compact}]"
                             :compact="form.compact"
                             @onInput="inputHandler"
                             @onChange="changeHandler"
                    >
                        <v-captcha v-if="(form.captcha || captcha) && form.fields[key].type === 'captcha'"></v-captcha>
                    </v-field>
                </div>
            </div>
        </template>
        <template v-else>
            <v-field v-for="item in form.fields"
                     :key="item"
                     :field="item"
                     :prefix="form.id"
                     :class="[item.cls, {field_compact:form.compact}]"
                     :compact="form.compact"
                     @onInput="inputHandler"
                     @onChange="changeHandler"
            >
                <v-captcha v-if="(form.captcha || captcha) && item.type === 'captcha'"></v-captcha>
            </v-field>
        </template>

        <v-button v-if="submitBtn" class="btn_primary"
                  :disabled="!form.valid"
                  @onClick="submitHandler">{{submitLabel}}
        </v-button>

        <v-terms v-if="terms"
                 :label="submitLabel"
        ></v-terms>
    </form>
</template>

<script>
    import {ref} from "vue";

    import Button from "@components/ui/forms/Button";
    import Errors from "@components/ui/forms/Errors";
    import Field from "@components/ui/forms/Field";
    import Captcha from "@components/ui/Captcha";
    import Terms from "@components/ui/forms/Terms";

    export default {
        components: {
            "v-button": Button,
            "v-field": Field,
            "v-captcha": Captcha,
            "v-error": Errors,
            "v-terms": Terms,
        },
        props: {
            form: {
                type: Object,
                required: true,
            },
            name: {
                type: String
            },
            url: {
                type: String,
                default: null
            },
            captcha: {
                type: Boolean,
                default: false
            },
            /*Делает поля компактнее*/
            compact: {
                type: Boolean,
                default: false
            },
            /*Показываь условие обработки песональных данных*/
            terms: {
                type: Boolean,
                default: false
            },
            submitBtn: {
                type: Boolean,
                default: false
            },
            submitLabel: {
                type: String,
                default: 'Отправить',
            }
        },
        name: "Form",
        setup(props, {emit}) {
            let captcha_value = ref(``);

            function inputHandler(value) {
                value.item.value = value.value;
                emit('onInput', props.form);
            }

            function changeHandler(value) {
                value.item.value = value.value;
                emit('onChange', props.form);
            }

            function submitHandler(e) {
                e.preventDefault();
                emit('onSubmit', props.form);
            }

            return {
                captcha_value,
                submitHandler,
                inputHandler,
                changeHandler
            }
        }
    }
</script>

<style lang="less">
    .form {
        &__title {
            font-size: var(--h4);
            margin-bottom: 1rem;
        }

        &__description {
            font-size: var(--h6);
            line-height: var(--line-height);
            margin-bottom: 1rem;
        }

        &__errors {
            outline: none;
            list-style-type: none;
            padding: 5px;
            margin-bottom: 1rem;
        }
    }
</style>
