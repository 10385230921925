export default  function useErrors(errors, fields, form= null) {
    if (typeof errors == 'object'){
        if (errors.constructor === Array){
            for (const item of errors) {
                fields[item.name].addError(item.name, item.msg)
            }
        }
    }else if(typeof errors === 'string'){
        form.addError(errors);
    }
}
