<template>
    <div class="terms">
        Нажимая кнопку <strong>«{{ label }}»</strong>, вы подтверждаете, что ознакомились с
        <a :href="url">Условиями обработки персональных данных</a> и принимаете их.
    </div>
</template>

<script>
export default {
    name: "Terms",
    props: {
        label: {
            type: String,
            default: 'Отправить'
        },
        url: {
            type: String,
            default: '/personal-data/'
        },
    },
}
</script>

<style lang="less">
.terms {
    margin-top: 1rem;
    color: var(--gray);
    font-size: var(--small);

    &,
    strong,
    a {
        line-height: 1rem;
    }

    strong,
    a {
        font-weight: bold;
        font-size: var(--small);
    }

    a {
        color: var(--secondary)
    }
}
</style>
