// import {required} from "@utils/validators";
// import useTemplate from "@use/native/template";

let nameField = {
    name: 'name',
    label: 'Имя и фамилия',
    value: '',
    type: 'text',
    // clear: true,
    // required: true, validators: {
    //     required: {
    //         validator: required,
    //         message: useTemplate`${'label'} - Обязательное поле для заполнения`,
    //     },
    // },
}

export default nameField
