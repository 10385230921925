<template>
    <div class="field"
         :style="{width :field.width}"
         :class="[{field_invalid: !field.valid && field.status}, {field_fill: value && compact}]">

        <div class="field__wrapper">
            <transition name="opacity">
                <v-button v-if="value && field.clear"
                          class="btn_sm field__clear"
                          @onClick="onClear"
                          title="Очистить поле">
                    <div class="ico-cross-sm"></div>
                </v-button>
            </transition>

            <label v-if="field.label"
                   class="field__label"
                   :for="`${field.name}-${prefix}`">{{ field.label }} <span v-if="field.required">*</span></label>

            <slot name="default"></slot>
            <textarea v-if="field.type==='textarea'" class="field__textarea"
                      :id="`${field.name}-${prefix}`"
                      :placeholder="field.placeholder"
                      :class="[{field_compact: compact}]"
                      autocomplete="off"
                      @blur="field.blur"
                      @focus="field.focus"
                      @input="inputHandler"
                      @change="changeHandler"
                      v-model="value"></textarea>

            <input v-else class="field__input"
                   :id="`${field.name}-${prefix}`"
                   :type="field.type"
                   :placeholder="field.placeholder"
                   :class="[{field_compact: compact}]"
                   autocomplete="off"
                   @blur="field.blur"
                   @focus="field.focus"
                   @input="inputHandler"
                   @change="changeHandler"
                   v-model="value">
        </div>

        <small v-if="field.help" class="field__help">{{ field.help }}</small>
        <transition name="fade">
            <v-errors v-if="field.status && !field.valid"
                      :errors="field.errors"
                      class="field__error"
            ></v-errors>
        </transition>
    </div>
</template>

<script>
import {ref} from 'vue'
import Errors from "@components/ui/forms/Errors";
import Button from "@components/ui/forms/Button";

export default {
    name: "Field",
    components: {
        "v-errors": Errors,
        "v-button": Button,
    },
    props: {
        field: Object,
        prefix: Number,
        compact: {
            type: Boolean,
            default: false
        },
    },
    setup(props, {emit}) {
        const value = ref(props.field.value);
        const inputHandler = () => emit('onInput', {value: value.value, item: props.field});
        const changeHandler = () => emit('onChange', {value: value.value, item: props.field});
        const onClear = (e) => {
            e.preventDefault();
            value.value = '';
        }

        return {
            value,
            inputHandler,
            changeHandler,
            onClear,
        }
    },
}
</script>

<style lang="less">
.field {
    --height: 40px;
    margin-bottom: 1rem;
    width: 100%;

    &__label {
        display: flex;
        margin-bottom: 10px;
        font-size: var(--label-size);
        color: var(--label-color);

        span {
            font-size: var(--p);
            font-weight: bold;
            color: var(--primary);
            margin-left: .25rem;
        }
    }

    &__textarea,
    &__input {
        display: flex;
        height: var(--height, 40px);
        width: 100%;

        font-family: var(--defaultFontFamily);
        font-size: var(--p);
        padding: 5px 10px;
        box-sizing: border-box;

        border-radius: var(--border-radius-sm);
        border: var(--border-size) solid var(--border-color);
        outline: 0;

        &:focus {
            border: var(--border-size) solid var(--success);
        }

        &::placeholder {
            font-size: var(--value);
        }
    }

    &__textarea {
        resize: none;
        min-height: 120px;
        width: 100%;
    }

    &__help {
        font-size: 11px;
        color: gray;
    }

    &__wrapper {
        position: relative;
    }

    &__clear {
        --padding: 4px;

        display: flex;
        justify-content: center;
        align-items: center;

        width: 30px;
        /*min-height: calc(var(--height) - (var(--padding) * 2));*/

        position: absolute;
        top: auto;
        bottom: var(--padding);
        right: var(--padding);

        background: var(--secondary);

        border-color: var(--secondary-dark);
        border-radius: var(--border-radius-sm);

        transition: background-color .3s ease-in-out;

        &:after {
            content: '×';
            width: 2rem;
            height: 2rem;
        }

        &:hover {
            background: var(--secondary-dark);
            border-color: var(--secondary-dark);
        }
    }

    &__errors {

    }

    &_invalid {
        .field {
            &__input {
                border: var(--border-size) solid var(--danger) !important;
            }
        }
    }

    &_horizontal {
        .field {
            &__wrapper {
                display: flex;
            }

            &__input,
            &__textarea {
                margin-left: .5rem;
            }
        }
    }

    &_compact {
        .field {
            &__label {
                pointer-events: none;
                background: var(--white);
                font-size: var(--small);
                margin: 0;
                position: absolute;
                left: .5rem;
                top: calc(var(--height) / 2 - 10px);
                line-height: 20px;
                padding: 0 4px;
                transition: top .2s ease;
            }
        }


        &:focus-within {
            .field {
                &__label {
                    top: -12px;
                }
            }
        }
    }

    &_fill {
        .field {
            &__label {
                top: -12px;
            }
        }
    }
}
</style>
