<template>
    <figure class="image"
            :class="{'loaded': isLoaded}"
    >
        <v-preloader v-if="isLoaded && exist" class="preloader"></v-preloader>
        <img v-if="exist" :src="src" :alt="alt" @load="loaded($event)">
        <span v-else class="no-image"></span>
    </figure>
</template>

<script>
import {ref} from "vue";
import Preloader from "@components/ui/Preloader";

export default {
    name: "Image",
    components: {
        "v-preloader": Preloader,
    },
    props: {
        src: {
            type: String,
            require: true
        },
        alt: {
            type: String,
            require: false
        },
        lazy: {
            type: Boolean,
            default: false
        },
        exist: {
            type: Boolean,
            default: true
        }
    },
    setup() {
        let isLoaded = ref(true);

        // watch(() => props.src, (src) => {
        //     console.log(src);
        // isLoaded.value = false;

        // const img = new Image();
        // img.onload = () => isLoaded.value = true;
        // img.src = src;
        // }, { immediate: true });

        function loaded() {
            isLoaded.value = false;
        }

        return {
            loaded,
            isLoaded
        }
    }
}
</script>

<style lang="less">
.image {
    position: relative;
    box-sizing: border-box;

    img {
        user-select: none;
        font-size: .625rem;
    }

    &_full {
        img {
            width: 100%;
            height: 100%;
        }
    }
}
</style>
