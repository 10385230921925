<template>
    <div class="captcha">
        <div class="captcha__image">
            <v-image :src="src"></v-image>
            <v-button class="captcha__update btn_sm btn_secondary"
                      @onClick="updateHandler"></v-button>
        </div>
    </div>
</template>

<script>
    import {ref} from "vue";

    import Image from "@components/ui/Image";
    import Button from "@components/ui/forms/Button";

    export default {
        name: "Captcha",
        components: {
            'v-image': Image,
            'v-button': Button,
        },
        props: {
            placeholder: {
                type: String,
                default: "Введите буквы с картинки"
            },
        },
        setup() {
            let src = ref(`/captcha/`);

            function updateHandler(e) {
                e.preventDefault();
                src.value = `/captcha/${Math.floor(Math.random() * 9999.9999)}/`;
            }

            return {
                src,
                updateHandler,
            }
        }
    }
</script>

<style lang="less">
    .captcha {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

        &__image {
            border-radius: var(--border-radius-sm);
            background: var(--primary);
            overflow: hidden;
            cursor: pointer;
            display: flex;
            align-items: center;
            height: var(--height, 40px);
            justify-content: space-between;

            img {
                min-width: 150px;
            }
        }

        &__update {
            margin-right: .375rem;
            padding: 0 .75rem;
            &:after {
                content: '';
                width: 18px;
                height: 22px;
                background: var(--white);
                mask-image: data-uri('image/svg+xml;charset=base64', "../../assets/img/icon/update.svg"), none;
            }
        }
    }
</style>
